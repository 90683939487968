<template>
  <div>
    <Loading :loading="false" />
    <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4"
            >
              <h6 class="m-0 text-white">
                {{ $t("YOUR_CREDITS") }}
              </h6>

              <h5 class="m-0 text-white">
                $0
                <div class="btn btn-light btn-sm">
                  <i class="bi-plus-lg"></i>
                </div>
              </h5>
            </div> -->
            <div class="card" v-if="!error">
              <div class="card-body">
                <div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12">
                        <div class="d-flex justify-content-between px-2">
                          <img
                            class="rounded mb-2"
                            :src="profile.imagePath"
                            style="
                              width: 100px;
                              height: 100px;
                              object-fit: cover;
                            "
                          />

                          <div>
                            <div class="text-center">
                              <p class="mb-0 mt-2">{{ $t("FOLLOWING") }}</p>
                              <h5>{{ profile.follow.following }}</h5>
                            </div>

                            <div class="text-center">
                              <p class="mb-0 mt-2">{{ $t("FOLLOWERS") }}</p>
                              <h5>{{ profile.follow.follower }}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <h5 class="card-title mb-0 ml-2">
                          {{ profile.username }}
                        </h5>
                        <hr />
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="text-center">
                              <p class="mb-0 mt-2">
                                {{ $t("TOTAL_PREDICTION") }}
                              </p>
                              <h6>{{ profile.stat.totalPrediction }}</h6>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="text-center">
                              <p class="mb-0 mt-2">{{ $t("WIN_RATE") }}</p>
                              <h6>{{ profile.stat.winRate }}</h6>
                            </div>
                          </div>
                          <!-- <div class="col-lg-4">
                            <div class="text-center">
                              <p class="mb-0 mt-2">{{ $t("YIELD") }}</p>
                              <h6>-2.5%</h6>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="/register" class="btn btn-primary btn-block">
              {{ $t("FOLLOW") }}
            </a>

            <div class="card" v-if="error">
              <div class="card-body">
                <div class="d-flex justify-content-between px-2">
                  <div class="col-10">
                    <div class="row">
                      <div class="col-1">
                        <div
                          class="rounded"
                          style="
                            width: 70px;
                            height: 70px;
                            background-color: #797979;
                          "
                        ></div>
                      </div>
                      <div class="col-3">
                        <h5 class="card-title mb-0 ml-2">-</h5>
                        <hr />
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="text-center">
                              <p class="mb-0 mt-2">
                                {{ $t("TOTAL_PREDICTION") }}
                              </p>
                              <h6>-</h6>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="text-center">
                              <p class="mb-0 mt-2">{{ $t("WIN_RATE") }}</p>
                              <h6>-</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-2">
                    <a
                      href="/register"
                      class="btn btn-primary btn-block"
                      :disabled="true"
                    >
                      {{ $t("FOLLOW") }}
                    </a>

                    <div class="d-flex justify-content-between">
                      <div class="text-center">
                        <p class="mb-0 mt-2">{{ $t("FOLLOWING") }}</p>
                        <h5>-</h5>
                      </div>

                      <div class="ml-4">
                        <div class="text-center">
                          <p class="mb-0 mt-2">{{ $t("FOLLOWERS") }}</p>
                          <h5>-</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="!error">
          <div class="col-lg-8 mt-4">
            <div class="card">
              <div class="card-body">
                <div id="chart">
                  <apexchart
                    type="line"
                    height="350"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 mt-4">
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"
            >
              <h6 class="m-0 text-white">{{ $t("TIPSTER_ACHIVEMENTS") }}</h6>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row ml-2">
                  <div class="col-2">
                    <img
                      class="rounded"
                      src="img/news-100x100-2.jpg"
                      style="width: 50px; height: 50px; object-fit: cover"
                    />
                  </div>
                  <div class="col-10">
                    <div class="ml-2 mb-4">
                      <h6 class="card-title mb-0">Fanatic</h6>
                      <p class="card-title mb-0">Post 10000 tips</p>

                      <div class="progress">
                        <div
                          class="progress-bar rounded"
                          role="progressbar"
                          aria-valuenow="20"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="height: 10px; width: 10%"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row ml-2 mb-4">
                  <div class="col-2">
                    <img
                      class="rounded"
                      src="img/news-100x100-2.jpg"
                      style="width: 50px; height: 50px; object-fit: cover"
                    />
                  </div>
                  <div class="col-10">
                    <div class="ml-2">
                      <h6 class="card-title mb-0">Real Winner</h6>
                      <p class="card-title mb-0">Win 500 tips</p>

                      <div class="progress">
                        <div
                          class="progress-bar rounded"
                          role="progressbar"
                          aria-valuenow="100"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style="height: 10px; width: 100%"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 mt-4">
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 rounded"
            >
              <h6 class="m-0 text-white">{{ $t("ACTIVE_TIPS") }}</h6>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 mt-1">
                    <Prediction :predictions="predictions" />
                  </div>
                  <div class="col-lg-4">
                    <MostPlayed />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Main News Slider End -->
  </div>
</template>

<script>
import Prediction from "./components2/Prediction.vue";

export default {
  computed: {
    currentRouteName() {
      return this.$routes.history.current.params.name;
    },
  },
  components: { Prediction },
  mounted() {
    this.$http
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/predictions`, {
        params: {
          username: JSON.parse(localStorage.getItem("userData")).username,
        },
      })
      .then(({ data }) => {
        this.predictions = data.data;
        console.log("predictions_user", this.predictions);
      })
      .catch((err) => {
        return;
      });

    try {
      this.$http
        .get(
          `${process.env.VUE_APP_BACKEND_API_URL}/users/${
            JSON.parse(localStorage.getItem("userData")).username
          }`
        )
        .then(({ data }) => {
          console.log("users", data);
          this.profile = data;
        })
        .catch((err) => {
          return;
        });
    } catch (error) {
      this.error = true;
      console.log(error);
    }
  },
  data() {
    return {
      error: false,
      profile: {},
      predictions: {},
      series: [
        {
          name: "Yield",
          data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
        },
        {
          name: "Profit",
          data: [2, 12, 66, 120, 20, 70, 76, 82, 200],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
        title: {
          text: "Product Trends by Month",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
          ],
        },
      },
    };
  },
  methods: {
    changeTab(index) {
      this.tab = index;
    },
  },
};
</script>

<style lang="scss"></style>
